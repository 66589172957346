import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"inventory-overview mb-5"},[_c(VSheet,{staticClass:"d-flex flex-column justify-end align-start pl-4",attrs:{"color":"Greyscale_002","height":"60"}},[_c('h5',[_c('span',{staticClass:"Main_0003--text"},[_c('b',[_vm._v("予約が確定しました。")])])])]),_c(VRow,{staticClass:"d-flex flex-column justify-end align-start"},[_c(VBtn,{staticClass:"mt-10 mx-auto",attrs:{"elevation":"0","fab":"","dark":"","large":"","color":"green"}},[_c(VIcon,{attrs:{"dark":"","x-large":""}},[_vm._v(" mdi-check ")])],1)],1),_c('div',{staticClass:"p-2 h6 my-5"},[_c(VCardText,[_vm._v(" ご予約いただき、ありがとうございます。"),_c('br'),_vm._v(" 予約内容を"),_c('b',[_vm._v(_vm._s(_vm.user.email))]),_vm._v("に送信しました。 また、キャンセル変更は予約のチェックイン前まで予約確認ページから可能です。 ")]),_c(VCardText,{staticClass:"text-h6 mt-5"},[_c('strong',{staticClass:"green--text"},[_vm._v(" 当日はカメラで、車の入庫を検知しますのでお越しの際は、そのまま駐車していただき特別な操作は必要ありません。 ")])])],1),_c('div',{staticClass:"mx-5"},[_c('router-link',{attrs:{"to":"/guest/reservations"}},[_c(VBtn,{attrs:{"color":"Main_0003","block":""}},[_c('span',{staticClass:"white--text"},[_vm._v(" 予約を確認する ")])])],1),_c('router-link',{attrs:{"to":"/guest/dashboard"}},[_c(VBtn,{staticClass:"my-5",attrs:{"color":"Main_0003","text":"","block":""}},[_vm._v(" Topページへ ")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }