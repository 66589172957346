import {alertService} from "@/services/alert/alert.service";
import {guestSettingProfileService} from "@/services/guest/setting/profile.service";

export default {
    data() {
        return {
            user: {},
            userReady: false,
            form: {
                auth_type: 'guest'
            },
            error: null
        }
    },
    async mounted() {
        const auth = JSON.parse(localStorage.getItem('app_auth'));
        this.get(auth.value.id)
    },
    methods: {
        async get(id) {
            this.userReady = false
            var response = await guestSettingProfileService.show(id);
            this.user = response.data
            this.userReady = true
        },
    }
}